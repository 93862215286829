import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { errorAlert, successAlert } from "../../utils/services";
import axiosInstance from "../../helpers/axios";
import CustomButton from "../../components/ui/CustomButton";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchDesignations } from "../../store/slices/globalSlice";

const AddDesignations = () => {
  const dispatch = useDispatch();

  //fetch designations
  useEffect(() => {
    dispatch(fetchDesignations());
  }, [dispatch]);

  const { designations  } = useSelector((state) => state.global);

  const [newDesignation, setNewDesignation] = useState({
    name: "",
    billed: false,
  });
  const [editDesignation, setEditDesignation] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleChange = (e) => {
    setNewDesignation({ ...newDesignation, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setNewDesignation({ ...newDesignation, billed: e.target.checked });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axiosInstance.post(`/designations`, newDesignation);
      successAlert("Designation added successfully!");
      setNewDesignation({ name: "", billed: false });
      dispatch(fetchDesignations());
    } catch (error) {
      errorAlert("Error adding designation. Please try again.");
    }
  };

  const handleEditClick = (designation) => {
    setEditDesignation(designation);
  };

  const handleEditChange = (e) => {
    setEditDesignation({ ...editDesignation, [e.target.name]: e.target.value });
  };

  const handleEditCheckboxChange = (e) => {
    setEditDesignation({ ...editDesignation, billed: e.target.checked });
  };

  const handleEditSubmit = async () => {
    try {
      await axiosInstance.put(
        `/designations/${editDesignation._id}`,
        editDesignation
      );
      setEditDesignation(null);
      dispatch(fetchDesignations());
      successAlert("Designation updated successfully!");
    } catch (error) {
      errorAlert("Error updating designation. Please try again.");
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axiosInstance.delete(`/designations/${deleteId}`);
      setOpen(false);
      setDeleteId(null);
      successAlert("Designation deleted successfully!");
      dispatch(fetchDesignations());
    } catch (error) {
      errorAlert("Error deleting designation. Please try again.");
    }
  };

  return (
    <Card elevation={0}>
      <ToastContainer />
      <CardContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h5">Add Designations</Typography>
          </Grid>
          <Grid item xs={12} md={6} mt={7}>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Designation Name"
                name="name"
                value={newDesignation.name}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newDesignation.billed}
                    onChange={handleCheckboxChange}
                    name="billed"
                  />
                }
                label="Billed"
              />
              <Grid container spacing={2} justifyContent="center" marginTop={2}>
                <Grid item xs={6}>
                  <CustomButton
                    variant="contained"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    Submit
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Designations
              </Typography>
            </Grid>
            <Grid container spacing={2} mt={3}>
              {designations.map((designation) => (
                <Grid item xs={12} key={designation._id}>
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="body1">
                            {designation.name} -{" "}
                            {designation.billed ? "Billed" : "Not Billed"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            color="primary"
                            onClick={() => handleEditClick(designation)}
                          >
                            <CiEdit />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={() => handleDeleteClick(designation._id)}
                          >
                            <RiDeleteBin6Line />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={Boolean(editDesignation)}
          onClose={() => setEditDesignation(null)}
        >
          <DialogTitle>Edit Designation</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Designation Name"
              name="name"
              value={editDesignation ? editDesignation.name : ""}
              onChange={handleEditChange}
              variant="outlined"
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={editDesignation ? editDesignation.billed : false}
                  onChange={handleEditCheckboxChange}
                  name="billed"
                />
              }
              label="Billed"
            />
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleEditSubmit} startIcon={<Check />}>
              Save
            </CustomButton>
            <CustomButton
              variant="outlined"
              onClick={() => setEditDesignation(null)}
              startIcon={<Close />}
            >
              Cancel
            </CustomButton>
          </DialogActions>
        </Dialog>

        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this designation?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleDeleteConfirm} startIcon={<Check />}>
              Confirm
            </CustomButton>
            <CustomButton
              variant="outlined"
              onClick={() => setOpen(false)}
              startIcon={<Close />}
            >
              Cancel
            </CustomButton>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AddDesignations;
