import React, { useState, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import CustomButton from './ui/CustomButton'; // Assuming you have a CustomButton component
import useResponsive from '../constants/useResponsive'; // Assuming you have a custom hook for responsiveness

const NoInternetConnection = (props) => {
  const [isOnline, setOnline] = useState(true);
  const { isSm } = useResponsive(); // Using the custom hook for responsiveness

  useEffect(() => {
    setOnline(navigator.onLine);

    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (isOnline) {
    return props.children;
  } else {
    return (
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: isSm ? 2 : 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: isSm ? 4 : 10,
          }}
        >
          
          {/* Inline SVG or Image */}
          <img
            src="https://comput-images.blr1.cdn.digitaloceanspaces.com/public/NoDataFound.gif"
            alt="No Data Found"
            className="w-full max-w-xs md:max-w-sm object-contain mt-4" // Prevent stretching
          />
          <Typography
            variant={isSm ? "h6" : "h4"}
            component="div"
            fontWeight={700}
            gutterBottom
            sx={{ fontSize: isSm ? "1.2rem" : "1.5rem" }}
          >
            No Internet Connection
          </Typography>
          <Typography
            variant={isSm ? "body1" : "h6"}
            component="div"
            gutterBottom
            paddingBottom={2}
            sx={{ fontSize: isSm ? "1rem" : "1.2rem" }}
          >
            Please check your connection and try again later.
          </Typography>
          
        </Box>
      </Container>
    );
  }
};

export default NoInternetConnection;
