import React from "react";

const Popup = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const emails = urlParams.get("emails");

  console.log("hello")

  return (
    <div>
      <h1>Email Sent</h1>
      <p>You sent an email to the following external recipients:</p>
      <p>{emails}</p>
    </div>
  );
};

export default Popup;
