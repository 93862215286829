import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import theme from "./constants/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import NoInternetConnection from "./components/NoInternetConnection";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NoInternetConnection>
        <App />
      </NoInternetConnection>
    </ThemeProvider>
  </React.StrictMode>
);

// Register the service worker to enable PWA features.
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    // Optional: Add logic to handle SW updates
    if (registration && registration.waiting) {
      if (window.confirm("New version available. Refresh now?")) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
      }
    }
  },
  onSuccess: (registration) => {
    console.log("Service Worker registered successfully:", registration);
  },
});

// Register background sync and periodic sync
navigator.serviceWorker.ready.then((registration) => {
  // Background Sync
  if ("sync" in registration) {
    registration.sync.register("syncTagName").catch((err) => {
      console.error("Background Sync registration failed:", err);
    });
  }

  // Periodic Sync
  if ("periodicSync" in registration) {
    registration.periodicSync
      .register("content-sync", {
        minInterval: 24 * 60 * 60 * 1000, // 1 day
      })
      .catch((err) => {
        console.error("Periodic Sync registration failed:", err);
      });
  }
});

// Request permission for push notifications and register
if ("Notification" in window && navigator.serviceWorker) {
  Notification.requestPermission((status) => {
    if (status === "granted") {
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              "BB6DLq1EiH_3A87_NcRRax1R7ZJb0oz8tBRx4DcdyEU52VYp7sqFseI-Q0ZzVsDdrzlz8MId0afjuFwu8qHJK5U"
            ),
          })
          .then((subscription) => {
            // Send the subscription object to your backend server for notifications
            console.log("Push Notification subscription:", subscription);
          })
          .catch((err) => {
            console.error("Push subscription failed:", err);
          });
      });
    }
  });
}

// Function to convert VAPID public key to UInt8Array
function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Performance monitoring (optional)
reportWebVitals();
