// src/features/matter/assignedMattersSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

// Async Thunk for fetching matters assigned to the current lawyer with optional pagination and search
export const fetchAssignedMatters = createAsyncThunk(
  "assignedMatters/fetchAssignedMatters",
  async (
    { rowsPerPage = 0, page = 0, search = "" } = {},
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosInstance.get(`/getAssignedMatters`, {
        params: {
          limit: rowsPerPage,
          page,
          search,
        },
      });

      const { matters, totalMatters, currentPage, totalPages } = res.data;

      return {
        matters,
        totalMatters,
        currentPage,
        totalPages,
      };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.error || "Failed to fetch assigned matters"
      );
    }
  }
);

// Create the slice
const assignedMattersSlice = createSlice({
  name: "assignedMatters",
  initialState: {
    matters: [],
    filteredMatters: [],
    totalMatters: 0,
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null,
  },
  reducers: {
    // If you need additional reducers, define here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignedMatters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssignedMatters.fulfilled, (state, action) => {
        state.matters = action.payload.matters;
        state.filteredMatters = [
          { value: "", label: "Select Matter *" },
          ...action.payload.matters
            .filter((matter) => matter.status === "enable")
            .map((matter) => ({
              value: matter._id,
              label: `${matter.name} | ${matter.client.name}`,
            })),
        ];
        state.totalMatters = action.payload.totalMatters;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(fetchAssignedMatters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default assignedMattersSlice.reducer;
