import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addClient, getAllClients } from "../../actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ConfirmationAlert,
  errorAlert,
  successAlert,
} from "../../utils/services";
import ReusableTable from "../../components/DataTable";
import axiosInstance from "../../helpers/axios";
import CustomModal from "../../components/ui/CustomModal";
import { RiDeleteBin6Line } from "react-icons/ri";

const ShowClient = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const adminClient = useSelector((state) => state.adminClient);
  const { clientList, totalClients, loading } = adminClient;

  // console.log("Client data", clientList);

  //STATES
  const [showModal, setShowModal] = useState(false);
  const [editingClient, setEditingClient] = useState(null);

  const [clientInfo, setClientInfo] = useState({
    name: "",
    address: "",
    loading: false,
  });

  const { name, address } = clientInfo;

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    dispatch(getAllClients(rowsPerPage, page));
  }, [dispatch, page, rowsPerPage]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //CLIENTS MAIN TABLE COLUMNS AND ROWS DATA SEND TO TABLE COMPONENT
  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "clientAddress", label: "Address", minWidth: 170 },
    { id: "edit", label: "Edit", minWidth: 70 },
    { id: "delete", label: "Delete", minWidth: 70 },
  ];

  const rows = clientList.map((client, index) => ({
    id: client._id,
    name: client.name,
    clientAddress: client.clientAddress,
  }));

  //FUNCTIONS TO DELETE RESOURCE
  const handleDeleteClient = (id) => {
    // console.log("Id", id);
    toast(
      <ConfirmationAlert
        question={"Are you sure to delete this client?"}
        onDeleteOff={() => onDeleteClient(id)}
        answer={"Yes, Delete it"}
        icon={<RiDeleteBin6Line />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteClient = async (deleteId) => {
    try {
      // console.log("clientIdToDelete", deleteId);

      const response = await axiosInstance.delete(
        `/client/deleteClient/${deleteId}`
      );

      if (response.data) {
        successAlert("Client Deleted Successfully!");
        dispatch(getAllClients(rowsPerPage, page));
      }
    } catch (error) {
      // console.log(error);
      errorAlert("Deletion of client failed", "Please try again...");
    }
  };

  //FUNCTIONS FOR OPEN AND CLOSE THE MODAL

  const openModal = (entryData) => {
    // console.log("User data", entryData);
    if (entryData) {
      setEditingClient(entryData);
      // console.log("Edit data", editingResource);
      setClientInfo({
        name: entryData.name || "",
        address: entryData.clientAddress || "",
      });
    } else {
      setEditingClient(null);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setClientInfo({
      ...clientInfo,
      name: "",
      address: "",
      loading: false,
    });
  };

  //FUNCTIONS TO CHECK THE FORM DATA VALIDATION

  const isValidObjField = () => {
    if (name === "") {
      return false;
    } else {
      return true;
    }
  };

  const isValidForm = () => {
    //all fields must be filled
    if (!isValidObjField()) return errorAlert("All Fields are Required!");

    return true;
  };

  const handleOnChangeText = (value, fieldName) => {
    setClientInfo({ ...clientInfo, [fieldName]: value });
  };

  //API CAll FOR ADD and UPDATE RESOURCE FEATUR
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidForm()) {
      setClientInfo({ ...clientInfo, loading: true });
      // console.log("ClientInfo", clientInfo);
      // console.log("editingClient", editingClient);
      try {
        if (!editingClient) {
          dispatch(await addClient(auth.user._id, name, address));
          setClientInfo({
            ...clientInfo,
            name: "",
            address: "",
            loading: false,
          });
          successAlert("Client Added Successfully!");
          dispatch(getAllClients(rowsPerPage, page));
        } else {
          try {
            // console.log("Edit section", clientInfo);

            const response = await axiosInstance.put(
              `/client/updateClient/${editingClient.id}`,
              {
                name: clientInfo.name,
                clientAddress: clientInfo.address,
              }
            );

            // console.log("Response of edit data", response.data);

            if (response.data) {
              dispatch(getAllClients(rowsPerPage, page));
              successAlert("Client Updated Successfully!");
            }
          } catch (error) {
            errorAlert("Error updating client details, try again...");
          }
        }
        closeModal();
      } catch (error) {
        console.error("Error:", error);
        errorAlert("Error : Something went wrong", error);
      }
    }
  };

  return (
    <div>
      <ToastContainer />

      <ReusableTable
        componentName={"Clients"}
        columns={columns}
        data={rows}
        onAdd={openModal}
        onEdit={openModal}
        onDelete={handleDeleteClient}
        loading={loading}
        totalCount={totalClients}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={closeModal}
          title={editingClient ? "EDIT CLIENT" : "ADD CLIENT"}
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            name="name"
            placeholder="Enter client name"
            value={name}
            onChange={(event) => handleOnChangeText(event.target.value, "name")}
          />

          <input
            type="address"
            value={address}
            name="email"
            placeholder="Enter client address"
            onChange={(event) =>
              handleOnChangeText(event.target.value, "address")
            }
          />
        </CustomModal>
      )}
    </div>
  );
};

export default ShowClient;

// import React, { useState, useEffect, useCallback } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { getAllClients, addClient } from "../../actions";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import {
//   ConfirmationAlert,
//   errorAlert,
//   successAlert,
// } from "../../utils/services";
// import axiosInstance from "../../helpers/axios";
// import CustomModal from "../../components/ui/CustomModal";
// import { RiDeleteBin6Line } from "react-icons/ri";

// // Material-UI Components
// import {
//   Typography,
//   Button,
//   CircularProgress,
//   Box,
//   TextField,
//   InputAdornment,
//   IconButton,
// } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";

// // Debounce
// import { debounce } from "lodash";
// import ClientAccordion from "../../components/admin/ClientAccordion";

// // Custom Components

// const ShowClient = () => {
//   const dispatch = useDispatch();
//   const auth = useSelector((state) => state.auth);
//   const adminClient = useSelector((state) => state.adminClient);
//   const { clientList, totalClients, loading } = adminClient;

//   // STATES
//   const [showModal, setShowModal] = useState(false);
//   const [editingClient, setEditingClient] = useState(null);

//   const [clientInfo, setClientInfo] = useState({
//     name: "",
//     address: "",
//     loading: false,
//   });

//   const { name, address } = clientInfo;

//   // Pagination states
//   const [page, setPage] = useState(1); // Start from page 1
//   const [rowsPerPage, setRowsPerPage] = useState(25);

//   // Search state
//   const [searchQuery, setSearchQuery] = useState("");

//   // Debounced Search
//   const handleSearch = useCallback(
//     debounce((value) => {
//       setSearchQuery(value);
//       setPage(1);
//     }, 300),
//     []
//   );

//   useEffect(() => {
//     dispatch(getAllClients(rowsPerPage, page, searchQuery));
//   }, [dispatch, page, rowsPerPage, searchQuery]);

//   // Cleanup debounce on unmount
//   useEffect(() => {
//     return () => {
//       handleSearch.cancel();
//     };
//   }, [handleSearch]);

//   // Pagination functions
//   const handleChangePage = (newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     const newLimit = parseInt(event.target.value, 10);
//     setRowsPerPage(newLimit);
//     setPage(1); // Reset to first page
//   };

//   // FUNCTIONS TO DELETE CLIENT
//   const handleDeleteClient = (id) => {
//     toast(
//       <ConfirmationAlert
//         question={"Are you sure you want to delete this client?"}
//         onDeleteOff={() => onDeleteClient(id)}
//         answer={"Yes, Delete it"}
//         icon={<RiDeleteBin6Line />}
//       />,
//       {
//         position: toast.POSITION.TOP_CENTER,
//         closeButton: true,
//         autoClose: false,
//         draggable: false,
//       }
//     );
//   };

//   const onDeleteClient = async (deleteId) => {
//     try {
//       const response = await axiosInstance.delete(
//         `/client/deleteClient/${deleteId}`
//       );

//       if (response.data) {
//         successAlert("Client Deleted Successfully!");
//         dispatch(getAllClients(rowsPerPage, page, searchQuery));
//       }
//     } catch (error) {
//       errorAlert("Deletion of client failed", "Please try again...");
//     }
//   };

//   // FUNCTIONS FOR OPEN AND CLOSE THE MODAL
//   const openModal = (entryData) => {
//     if (entryData) {
//       setEditingClient(entryData);
//       setClientInfo({
//         name: entryData.name || "",
//         address: entryData.clientAddress || "",
//       });
//     } else {
//       setEditingClient(null);
//     }
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setClientInfo({
//       ...clientInfo,
//       name: "",
//       address: "",
//       loading: false,
//     });
//   };

//   // FUNCTIONS TO CHECK THE FORM DATA VALIDATION
//   const isValidObjField = () => {
//     return name.trim() !== "";
//   };

//   const isValidForm = () => {
//     if (!isValidObjField()) {
//       return errorAlert("All Fields are Required!");
//     }
//     return true;
//   };

//   const handleOnChangeText = (value, fieldName) => {
//     setClientInfo({ ...clientInfo, [fieldName]: value });
//   };

//   // API CALL FOR ADD and UPDATE CLIENT
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (isValidForm()) {
//       setClientInfo({ ...clientInfo, loading: true });
//       try {
//         if (!editingClient) {
//           await dispatch(addClient(auth.user._id, name, address));
//           setClientInfo({
//             ...clientInfo,
//             name: "",
//             address: "",
//             loading: false,
//           });
//           successAlert("Client Added Successfully!");
//           dispatch(getAllClients(rowsPerPage, page, searchQuery));
//         } else {
//           try {
//             const response = await axiosInstance.put(
//               `/client/updateClient/${editingClient.id}`,
//               {
//                 name: clientInfo.name,
//                 clientAddress: clientInfo.address,
//               }
//             );

//             if (response.data) {
//               dispatch(getAllClients(rowsPerPage, page, searchQuery));
//               successAlert("Client Updated Successfully!");
//             }
//           } catch (error) {
//             errorAlert("Error updating client details, try again...");
//           }
//         }
//         closeModal();
//       } catch (error) {
//         console.error("Error:", error);
//         errorAlert("Error : Something went wrong", error);
//       }
//     }
//   };

//   return (
//     <Box p={3}>
//       <ToastContainer />

//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         mb={2}
//       >
//         <Typography variant="h4">Clients</Typography>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => openModal(null)}
//         >
//           Add Client
//         </Button>
//       </Box>

//       {/* Search Bar */}
//       <Box mb={2}>
//         <TextField
//           label="Search Clients"
//           variant="outlined"
//           fullWidth
//           onChange={(e) => handleSearch(e.target.value)}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton edge="end" aria-label="search">
//                   <SearchIcon />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//         />
//       </Box>

//       {loading ? (
//         <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
//           <CircularProgress />
//         </Box>
//       ) : (
//         <div>
//           {clientList.length === 0 ? (
//             <Typography>No clients found.</Typography>
//           ) : (
//             clientList.map((client) => (
//               <ClientAccordion
//                 key={client._id}
//                 client={client}
//                 openModal={openModal}
//                 handleDeleteClient={handleDeleteClient}
//               />
//             ))
//           )}
//         </div>
//       )}

//       {/* Pagination Controls */}
//       {totalClients > rowsPerPage && (
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           mt={2}
//         >
//           <Typography>
//             Showing {(page - 1) * rowsPerPage + 1} to{" "}
//             {page * rowsPerPage > totalClients
//               ? totalClients
//               : page * rowsPerPage}{" "}
//             of {totalClients} Clients
//           </Typography>
//           <Box>
//             <Button
//               variant="contained"
//               onClick={() => handleChangePage(page - 1)}
//               disabled={page === 1}
//               sx={{ mr: 1 }}
//             >
//               Previous
//             </Button>
//             <Button
//               variant="contained"
//               onClick={() => handleChangePage(page + 1)}
//               disabled={page * rowsPerPage >= totalClients}
//             >
//               Next
//             </Button>
//           </Box>
//           <Box>
//             <Typography variant="body2" component="span" mr={1}>
//               Rows per page:
//             </Typography>
//             <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
//               <option value={10}>10</option>
//               <option value={25}>25</option>
//               <option value={50}>50</option>
//               <option value={100}>100</option>
//             </select>
//           </Box>
//         </Box>
//       )}

//       {/* Modal for Adding/Editing Client */}
//       {showModal && (
//         <CustomModal
//           showModal={showModal}
//           closeModal={closeModal}
//           title={editingClient ? "EDIT CLIENT" : "ADD CLIENT"}
//           onSubmit={handleSubmit}
//         >
//           <Box display="flex" flexDirection="column" gap={2}>
//             <TextField
//               label="Client Name"
//               variant="outlined"
//               value={name}
//               onChange={(event) =>
//                 handleOnChangeText(event.target.value, "name")
//               }
//               required
//             />

//             <TextField
//               label="Client Address"
//               variant="outlined"
//               value={address}
//               onChange={(event) =>
//                 handleOnChangeText(event.target.value, "address")
//               }
//               required
//             />

//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               disabled={clientInfo.loading}
//             >
//               {clientInfo.loading ? <CircularProgress size={24} /> : "Submit"}
//             </Button>
//           </Box>
//         </CustomModal>
//       )}
//     </Box>
//   );
// };

// export default ShowClient;
