import axios from "axios";
import store from "../store";
import { authConstants } from "../actions/constants";
import storage from "../utils/storage"; // Import the storage utility

// Helper function to retrieve the auth token
const getToken = () => {
  const token = storage.getItem("authToken");
  return token ? token : null;
};

// Create an Axios instance without setting a static baseURL
const axiosInstance = axios.create({
  // baseURL will be set dynamically in the request interceptor
  headers: {
    Authorization: getToken() ? `Bearer ${getToken()}` : "",
  },
});

// Request interceptor to set baseURL and Authorization header dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the API base URL from localStorage
    // const apiBaseUrl = "http://192.168.0.117:8000/api/v1";
    // const apiBaseUrl = "http://192.168.0.241:8000/api/v1";
    //  const apiBaseUrl = "http://localhost:8000/api/v1";
    // const apiBaseUrl = "http://192.168.68.138:8000/api/v1";
    const apiBaseUrl = storage.getItem("API_BASE_URL");

    if (apiBaseUrl) {
      config.baseURL = apiBaseUrl;
      // console.log(`Setting baseURL to: ${apiBaseUrl}`); // Debugging
    } else {
      // console.warn("API_BASE_URL is not set in localStorage.");
      // Optionally, set a default baseURL or handle the absence accordingly
      // config.baseURL = 'https://default.api.com';
    }

    // Retrieve the latest auth token before each request
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization; // Remove Authorization header if no token
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle global errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("API Response Error:", error.response);

    const status = error.response ? error.response.status : null;
    if (status === 500) {
      // Clear all stored data and dispatch logout action
      storage.clear();
      store.dispatch({ type: authConstants.LOGOUT_SUCCESS });
      // Optionally, redirect the user to the login page
      window.location.href = "/"; // Adjust the path as needed
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
