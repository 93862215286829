import React, { useCallback, useEffect, useState } from "react";
import formatDate, {
  errorAlert,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Box,
  Typography,
  Card,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axiosInstance from "../../helpers/axios";
import Header from "../../components/ui/Header";
import { COLORS } from "../../constants/theme";
// import SkeletonRows from "../../components/ui/CardSkeletonLoader";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomButton from "../../components/ui/CustomButton";
import NoDataFound from "../../components/NoDataFound";
import useResponsive from "../../constants/useResponsive";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";

const ResourceLeave = () => {
  const auth = useSelector((state) => state.auth);
  const { isSm } = useResponsive();

  //breakpoints
  const { isMd } = useResponsive();

  const [leaves, setLeaves] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totalLeavesCount, setTotalLeavesCount] = useState(0);

  //modal states
  const [modalStartDate, setModalStartDate] = useState(formatDate(new Date()));
  const [modalEndDate, setModalEndDate] = useState(formatDate(new Date()));
  const [modalLeaveDescription, setModalLeaveDescription] = useState("");
  const [editingLeave, setEditingLeave] = useState(null);
  const [leaveType, setLeaveType] = useState("");

  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [loading, setLoading] = useState(false);

  //get leaves
  const fetchAllLeaves = useCallback(async () => {
    try {
      // setLoading(true);
      const response = await axiosInstance.get(`/leaves/my-leaves`, {
        params: {
          limit: rowsPerPage,
          page,
        },
      });
      setLeaves(response.data.leavesWithDays);
      setTotalLeavesCount(response.data.totalLeavesCount);
      // setLoading(false);
      // console.log("Resource Leaves data------", response.data.leavesWithDays);
    } catch (error) {
      // setLoading(false);

      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // console.error("Error fetching leaves:", errorMessage);
      errorAlert(errorMessage);
      setLeaves([]);
    }
  }, [rowsPerPage, page]);

  useEffect(() => {
    fetchAllLeaves();
  }, [fetchAllLeaves, page, rowsPerPage]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Modal functions
  const openModal = (leave) => {
    if (leave) {
      setEditingLeave(leave);
      setModalStartDate(leave.leaveStartDate);
      setModalEndDate(leave.leaveEndDate);
      setModalLeaveDescription(leave.leaveDescription);
      //  console.log("leave data", leave, editingLeave)
    } else {
      setEditingLeave(null);
      setModalStartDate(formatDate(new Date()));
      setModalEndDate(formatDate(new Date()));
      setModalLeaveDescription("");
    }
    setShowModal(true);
  };

  //modal form validation
  const modalIsValidForm = () => {
    if (modalStartDate === "" || modalEndDate === "") {
      errorAlert("Start Date and End Date are required!!");
      return false;
    } else if (!(modalStartDate <= modalEndDate)) {
      errorAlert("Select Correct Dates!!");
      return false;
    } else {
      return true;
    }
  };

  // console.log(auth.user.fullName);

  //add and update leave function
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (modalIsValidForm()) {
      const formData = {
        admin: auth.user.company,
        user: auth.user._id,
        fullName: auth.user.fullName,
        leaveStartDate: modalStartDate,
        leaveEndDate: modalEndDate,
        leaveDescription: modalLeaveDescription,
        leaveType: leaveType,
        leaveStatus: "pending",
        paidLeave: "yes",
      };

      //  console.log(formData);

      try {
        if (editingLeave) {
          // Update existing leave
          const response = await axiosInstance.put(
            `/leaves/${editingLeave._id}`,
            formData
          );
          if (response.data) {
            successAlert(response.data.message);
            setShowModal(false);
            fetchAllLeaves();
            
            
          }
        } else {
          // Add new leave
          const response = await axiosInstance.post(`/leaves`, formData);
          if (response.data) {
            successAlert(response.data.message);
            setShowModal(false);
            fetchAllLeaves();
          }
        }
      } catch (error) {
        errorAlert(
          error.response.data.message ||
            (editingLeave
              ? "Error updating leave!! Try again..."
              : "Error creating leave!! Try again...")
        );
      }
    }
  };

  const userJson = localStorage.getItem("user");
  const user = JSON.parse(userJson);
  // console.log(user.totalLeaves);

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setModalStartDate(startDate);
    setModalEndDate(endDate);
    // console.log(startDate, endDate);
  };

  return (
    <>
      <Header />
      <ToastContainer />
      {/* Main Heading  */}
      <Grid
        item
        sm={6}
        md={6}
        lg={6}
        gap={1}
        display="flex"
        alignItems="center"
        paddingX={5}
        marginTop={2}
      >
        <Typography variant="h5">Leaves</Typography>
        <CustomTooltip text={"Add Leave"}>
          <CustomButton
            style={{
              borderRadius: "50%",
              minWidth: "30px", // Minimum width
              width: "40px", // Fixed width
              height: "35px", // Fixed height
              flexShrink: 0, // Prevents the button from stretching
              transition:
                "transform 0.3s ease-in-out, background-color 0.3s ease",
              "&:hover": {
                backgroundColor: COLORS.primary,
                transform: "scale(1.1)",
              },
            }}
            onClick={() => openModal(null)}
          >
            <AddIcon />
          </CustomButton>
        </CustomTooltip>
      </Grid>

      {/* Add / Edit Modal  */}
      {showModal && (
        <div className="modal">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <Typography variant="h5" align="center">
              {editingLeave ? "Edit Leave" : "Add Leave"}
            </Typography>

            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
              <Grid
                container
                spacing={2}
                className="flex justify-center align-middle"
              >
                <Grid item sm={12} md={8} xs={12}>
                  <select
                    style={{
                      width: "100%",
                      backgroundColor: "white", // Setting background color to white
                    }}
                    value={leaveType}
                    name="leaveType"
                    onChange={(event) => setLeaveType(event.target.value)}
                  >
                    <option value="">Select Leave Type</option>
                    <option value="leave">Leave</option>
                    <option value="workFromHome">Work From Home</option>
                  </select>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <CustomTooltip text="Select Dates">
                    <CustomDateRangePicker
                      startDate={selectedDateRange.start}
                      endDate={selectedDateRange.end}
                      onDateRangeChange={handleDateRangeChange}
                      iconSize={30}
                      iconColor={COLORS.primary}
                    />
                  </CustomTooltip>
                  <Typography>
                    {formatMonthDate(modalStartDate)} -{" "}
                    {formatMonthDate(modalEndDate)}
                  </Typography>
                </Grid>
              </Grid>

              <textarea
                style={{ width: "100%", padding: "5px" }}
                type="text"
                placeholder="Leave description"
                value={modalLeaveDescription}
                onChange={(e) => setModalLeaveDescription(e.target.value)}
              />

              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <CustomButton type="submit">Submit</CustomButton>
                <CustomButton
                  variant="outlined"
                  style={{ width: "20%" }}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </CustomButton>
              </Box>
            </form>
          </div>
        </div>
      )}

      {/* Total Table  */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={isMd ? 2 : 5}
      >
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <TableContainer>
            <Table aria-label="reusable table">
              <TableHead>
                {/* columns row  */}
                <TableRow sx={{ backgroundColor: "#f8f8f8" }}>
                  <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Assigned</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Used</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Available
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={1}>Leaves</TableCell>
                  <TableCell>{user.totalLeaves}</TableCell>
                  <TableCell>
                    {leaves
                      .filter(
                        (entry) =>
                          entry.leaveType !== "workFromHome" &&
                          (entry.leaveStatus === "pending" ||
                            entry.leaveStatus === "approved")
                      )
                      .reduce(
                        (total, curr) => total + parseFloat(curr.leaveDays),
                        0
                      ) || 0}
                  </TableCell>
                  <TableCell>
                    {user.totalLeaves -
                      leaves
                        .filter(
                          (entry) =>
                            entry.leaveType !== "workFromHome" &&
                            entry.leaveStatus === "approved"
                        )
                        .reduce(
                          (total, curr) => total + parseFloat(curr.leaveDays),
                          0
                        ) || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1}>Work From Home</TableCell>
                  <TableCell>{user.totalWorkFromHome}</TableCell>
                  <TableCell>
                    {leaves
                      .filter(
                        (entry) =>
                          entry.leaveType === "workFromHome" &&
                          (entry.leaveStatus === "pending" ||
                            entry.leaveStatus === "approved")
                      )
                      .reduce(
                        (total, curr) => total + parseFloat(curr.leaveDays),
                        0
                      )}
                  </TableCell>
                  <TableCell>
                    {user.totalWorkFromHome -
                      leaves
                        .filter(
                          (entry) =>
                            entry.leaveType === "workFromHome" &&
                            (entry.leaveStatus === "pending" ||
                              entry.leaveStatus === "approved")
                        )
                        .reduce(
                          (total, curr) => total + parseFloat(curr.leaveDays),
                          0
                        )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>

      <Grid
        container
        paddingX={isMd ? 2 : 5}
        paddingY={2}
        flexDirection="column"
      >
        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalLeavesCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            padding: isSm ? "0 5px" : "0 16px",
            "& .MuiTablePagination-toolbar": {
              fontSize: "12px", // Text size for the toolbar
            },
            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
              {
                fontSize: isSm ? "12px" : "", // Text size for labels and displayed rows
              },
            "& .MuiTablePagination-select": {
              fontSize: isSm ? "12px" : "", // Text size for the dropdown (e.g., "25")
              color: "black", // Match the text color of the rest of the style
              fontWeight: "bold", // Optional: Bold text for emphasis
            },
            "& .MuiTablePagination-select:hover": {
              backgroundColor: "transparent", // Optional: Ensure no hover effect for dropdown
            },
            "& .MuiTablePagination-select:focus": {
              outline: "none", // Remove focus outline if needed
            },
            "& .MuiTablePagination-actions button": {
              fontSize: "16px", // Icon button size
            },
          }}
        />

        {/* Header */}
        <Paper
          elevation={0}
          sx={{
            padding: 2,
            marginBottom: 2,
            borderRadius: "10px",
            width: "100%",
            backgroundColor: "#f8f8f8", // Light background for header
            border: "1px solid #e0e0e0",
          }}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={3} sm={3} md={2}>
              <Typography
                fontSize={{ xs: "12px", sm: "14px" }}
                fontWeight="bold"
              >
                Date
              </Typography>
            </Grid>

            <Grid item xs={3} sm={3} md={2}>
              <Typography
                fontSize={{ xs: "12px", sm: "14px" }}
                fontWeight="bold"
              >
                Leave Type
              </Typography>
            </Grid>

            <Grid item xs={3} sm={3} md={2}>
              <Typography
                fontSize={{ xs: "12px", sm: "14px" }}
                fontWeight="bold"
              >
                Days
              </Typography>
            </Grid>

            {!isMd && (
              <Grid item xs={0} sm={0} md={3}>
                <Typography
                  fontSize={{ xs: "12px", sm: "14px" }}
                  fontWeight="bold"
                >
                  Description
                </Typography>
              </Grid>
            )}

            <Grid item xs={3} sm={3} md={2}>
              <Typography
                fontSize={{ xs: "12px", sm: "14px" }}
                fontWeight="bold"
              >
                Status
              </Typography>
            </Grid>

            {!isMd && (
              <Grid item xs={0} sm={0} md={1}>
                <Typography
                  fontSize={{ xs: "12px", sm: "14px" }}
                  fontWeight="bold"
                >
                  Paid Leave
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>

        {/* Loading Skeleton or No Data Message */}
        {
          // loading ? (
          //   <SkeletonRows rows={6} />
          // ) :
          leaves.length === 0 ? (
            <Grid item xs={12}>
              <NoDataFound message="Oops! No Leaves Found." />
            </Grid>
          ) : (
            // Leave Cards
            leaves.map((leave) => (
              <Card
                elevation={0}
                key={leave._id}
                sx={{
                  padding: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  width: "100%",
                  overflow: "hidden",
                  wordBreak: "break-word",
                  boxSizing: "border-box",
                  marginY: 1,
                }}
              >
                <Grid container alignItems="center" spacing={2}>
                  {/* Date */}
                  <Grid item xs={3} sm={3} md={2}>
                    <Typography
                      fontSize={{ xs: "12px", sm: "12px" }}
                      color={COLORS.primary}
                    >
                      {leave.leaveStartDate === leave.leaveEndDate
                        ? formatMonthDate(leave.leaveStartDate)
                        : `${formatMonthDate(
                            leave.leaveStartDate
                          )} - ${formatMonthDate(leave.leaveEndDate)}`}
                    </Typography>
                  </Grid>

                  {/* Leave Type */}
                  <Grid item xs={3} sm={3} md={2}>
                    <Typography
                      fontSize={{ xs: "12px", sm: "12px" }}
                      fontWeight="700"
                    >
                      {leave.leaveType === "workFromHome"
                        ? "Work From Home"
                        : leave.leaveType === "quarterDay"
                        ? "Quarter Day"
                        : leave.leaveType === "halfDay"
                        ? "Half Day"
                        : "Leave"}
                    </Typography>
                  </Grid>

                  {/* Days */}
                  <Grid item xs={3} sm={3} md={2}>
                    <Typography
                      fontSize={{ xs: "12px", sm: "12px" }}
                      fontWeight="700"
                    >
                      {leave.leaveDays}
                    </Typography>
                  </Grid>

                  {/* Description */}
                  {!isMd && (
                    <Grid item xs={0} sm={0} md={3}>
                      <Typography
                        fontSize={{ xs: "12px", sm: "12px" }}
                        color={COLORS.primary}
                      >
                        {leave.leaveDescription === ""
                          ? "N/A"
                          : leave.leaveDescription}
                      </Typography>
                    </Grid>
                  )}

                  {/* Status */}
                  <Grid item xs={3} sm={3} md={2}>
                    <Typography
                      fontSize={{ xs: "12px", sm: "12px" }}
                      fontWeight="700"
                      sx={{
                        color:
                          leave.leaveStatus === "approved"
                            ? "green"
                            : leave.leaveStatus === "pending"
                            ? "orange"
                            : "red",
                      }}
                    >
                      {leave.leaveStatus === "pending"
                        ? "Pending"
                        : leave.leaveStatus === "approved"
                        ? "Approved"
                        : "Rejected"}
                    </Typography>
                  </Grid>

                  {/* Paid Leave */}
                  {!isMd && (
                    <Grid item xs={0} sm={0} md={1}>
                      <Typography fontSize={{ xs: "12px", sm: "12px" }}>
                        {leave.paidLeave === "yes" ? "Paid" : "Not Paid"}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Card>
            ))
          )
        }
      </Grid>

      {window.innerWidth <= 768 ? (
        <Grid item xs={12}>
          <Box sx={{ height: "70px" }}></Box> {/* Add 50px of space */}
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default ResourceLeave;
