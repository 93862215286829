import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

// Thunk for fetching employees with optional parameters
export const fetchEmployees = createAsyncThunk(
  "employee/fetchEmployees",
  async ({ rowsPerPage = 0, page = 0 } = {}, { getState, rejectWithValue }) => {
    try {
      const { unBilledDesignations } = getState().global;

      const res = await axiosInstance.get(`/employee/getAll`, {
        params: { limit: rowsPerPage, page },
      });

      const employees = res.data.employees;
      const totalCountOfEmployees = res.data.totalCountOfEmployees;
      const activeEmployeeCount = res.data.activeEmployeeCount;

      // Process employees data based on the logic in the custom hook
      const employeesData = employees.filter(
        (emp) =>
          !unBilledDesignations.includes(emp.designation) &&
          emp.status === "active"
      );

      const allActiveEmployeesData = employees.filter(
        (emp) => emp.status === "active"
      );

      const allEmployees = employees
        .filter((item) => item.status === "active")
        .map((employee) => ({
          value: employee._id,
          label: `${employee.fullName}`,
        }));

      const filteredEmployees = employees
        .filter(
          (item) =>
            !unBilledDesignations.includes(item.designation) &&
            item.status === "active"
        )
        .map((employee) => ({
          value: employee._id,
          label: `${employee.fullName}`,
        }));

      const tsEmployees = employees.filter(
        (item) => !unBilledDesignations.includes(item.designation)
      );

      return {
        employees,
        employeesData,
        allActiveEmployeesData,
        allEmployees: [{ value: "", label: "Select All" }, ...allEmployees],
        filteredEmployees: [
          { value: "", label: "Select All" },
          ...filteredEmployees,
        ],
        tsEmployees,
        totalCountOfEmployees,
        activeEmployeeCount,
      };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.error || "Failed to fetch employees."
      );
    }
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employees: [],
    employeesData: [],
    allActiveEmployeesData: [],
    allEmployees: [],
    filteredEmployees: [],
    tsEmployees: [],
    totalCountOfEmployees: 0,
    activeEmployeeCount: 0,
    employeeLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.employeeLoading = true;
        state.error = null;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.employees = action.payload.employees;
        state.employeesData = action.payload.employeesData;
        state.allActiveEmployeesData = action.payload.allActiveEmployeesData;
        state.allEmployees = action.payload.allEmployees;
        state.filteredEmployees = action.payload.filteredEmployees;
        state.tsEmployees = action.payload.tsEmployees;
        state.totalCountOfEmployees = action.payload.totalCountOfEmployees;
        state.activeEmployeeCount = action.payload.activeEmployeeCount;
        state.employeeLoading = false;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.error = action.payload;
        state.employeeLoading = false;
      });
  },
});

export default employeeSlice.reducer;
