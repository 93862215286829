import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  CssBaseline,
  MenuItem,
  Select,
  Typography,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import LineChart from "../components/graphs/LineGraphs";
import axiosInstance from "../helpers/axios";
import Header from "../components/ui/Header";
import { format } from "date-fns";
import useResponsive from "../constants/useResponsive";
import CustomButton from "../components/ui/CustomButton";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../constants/theme";
import { PlusCircleFilled } from "@ant-design/icons";
import { formatMonthDate } from "../utils/services";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Divider from "@mui/material/Divider";

const ResourceDashboard = () => {
  const navigate = useNavigate();
  const todayDate = format(new Date(), "MMMM d, yyyy");
  const { isSm, isXl } = useResponsive();

  const [activeInterval, setActiveInterval] = useState("monthly");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [startEndDate, setStartEndDate] = useState({ start: "", end: "" });
  const [dashboardTotals, setDashboardTotals] = useState({
    todayHours: 0,
    todayBillableHours: 0,
    todayNonBillableHours: 0,
    thisWeekHours: 0,
    thisWeekWriteoffHours: 0,
    thisWeekBillableHours: 0,
    thisWeekNonBillableHours: 0,
    lastWeekHours: 0,
    lastWeekWriteoffHours: 0,
    lastWeekBillableHours: 0,
    lastWeekNonBillableHours: 0,
    thisMonthHours: 0,
    thisMonthWriteoffHours: 0,
    thisMonthBillableHours: 0,
    thisMonthNonBillableHours: 0,
    thisYearHours: 0,
    thisYearTotalBillable: 0,
    thisYearTotalNonBillable: 0,
  });

  // Fetch Dashboard Data
  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(
        `/workHour/resourceDashboardTotals`
      );
      const data = response.data;

      setDashboardTotals({
        todayHours: data.todayHours,
        todayBillableHours: data.todayBillableHours,
        todayNonBillableHours: data.todayNonBillableHours,
        thisWeekHours: data.thisWeekHours,
        thisWeekWriteoffHours: data.thisWeekWriteoffHours,
        thisWeekBillableHours: data.thisWeekBillableHours,
        thisWeekNonBillableHours: data.thisWeekNonBillableHours,
        lastWeekHours: data.lastWeekHours,
        lastWeekWriteoffHours: data.lastWeekWriteoffHours,
        lastWeekBillableHours: data.lastWeekBillableHours,
        lastWeekNonBillableHours: data.lastWeekNonBillableHours,
        thisMonthHours: data.thisMonthHours,
        thisMonthWriteoffHours: data.thisMonthWriteoffHours,
        thisMonthBillableHours: data.thisMonthBillableHours,
        thisMonthNonBillableHours: data.thisMonthNonBillableHours,
        thisYearHours: data.thisYearTotalHours,
        thisYearTotalBillable: data.thisYearTotalBillable,
        thisYearTotalNonBillable: data.thisYearTotalNonBillable,
      });
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  // Get labels for the interval
  const getLabelsForInterval = useCallback((interval) => {
    switch (interval) {
      case "daily":
        return getLastNDaysLabels(7);
      case "weekly":
        return getLastNWeeksLabels(4);
      case "monthly":
        return getLastNMonthsLabels(12);
      case "yearly":
        return getLastNYearsLabels(4);
      default:
        return [];
    }
  }, []);

  // Get last n days labels
  const getLastNDaysLabels = (n) => {
    const labels = [];
    const today = new Date();

    for (let i = n - 1; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      labels.push(formatMonthDate(date.toISOString().split("T")[0]));
    }
    return labels;
  };

  // Get last n weeks labels
  const getLastNWeeksLabels = (n) => {
    const labels = [];
    const today = new Date();

    for (let week = n - 1; week >= 0; week--) {
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay() - (week - 1) * 7);
      labels.push(formatMonthDate(startOfWeek.toISOString().split("T")[0]));
    }

    return labels;
  };

  // Get last n months labels
  const getLastNMonthsLabels = (n) => {
    const labels = [];
    const today = new Date();

    for (let month = n - 1; month >= 0; month--) {
      const firstOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() - month,
        1
      );
      labels.push(
        firstOfMonth.toLocaleDateString("default", { month: "short" })
      );
    }

    return labels;
  };

  // Get last n years labels
  const getLastNYearsLabels = (n) => {
    const labels = [];
    const currentYear = new Date().getFullYear();

    for (let year = n - 1; year >= 0; year--) {
      labels.push(`${currentYear - year}`);
    }

    return labels;
  };

  // Fetch User Work Hours
  const getUserWorkHours = async (interval) => {
    try {
      const response = await axiosInstance.get(
        `/workHour/resourceChartTotal`,
        {
          params: { interval },
        }
      );

      if (response.data.workHoursData) {
      }
      return response.data.workHoursData;
    } catch (error) {
      console.error("Error fetching user work hours:", error);
      return [];
    }
  };

  // Handle interval change and update start/end dates
  const handleIntervalChange = useCallback(
    async (interval) => {
      setActiveInterval(interval);

      // Fetch data from the backend
      const workHoursData = await getUserWorkHours(interval);

      const labels = getLabelsForInterval(interval);
      const availableHoursData = [];
      const billableHoursData = [];
      const billedHoursData = [];

      // Assuming 7 hours a day, 35 hours a week, 154 hours a month, 1820 hours a year
      const availableHours = {
        daily: 7,
        weekly: 35,
        monthly: 154,
        yearly: 1820,
      };

      workHoursData.forEach(
        ({
          totalWorkHours,
          totalWriteOffHours,
          billableWorkHours,
          billedWorkHours,
        }) => {
          availableHoursData.push(availableHours[interval]);
          billableHoursData.push(billableWorkHours);
          billedHoursData.push(billedWorkHours);
        }
      );

      const newChartData = {
        labels,
        datasets: [
          {
            label: "Available Hours",
            data: availableHoursData,
            fill: true,
            borderColor: "rgba(52, 199, 89, 1)",
            backgroundColor: "rgba(52, 199, 89, 1)",
          },
          {
            label: "Clocked Hours",
            data: [...billableHoursData].reverse(), // Avoid mutating the original array
            borderColor: "rgba(45, 207, 245, 1)",
            backgroundColor: "rgba(45, 207, 245, 1)",
          },
          {
            label: "Billed Hours",
            data: [...billedHoursData].reverse(), // Avoid mutating the original array
            borderColor: "rgba(255, 221, 0, 1)",
            backgroundColor: "rgba(255, 221, 0, 1)",
          },
        ],
      };

      setChartData(newChartData);

      // Update start and end date
      setStartEndDate({
        start: labels[labels.length - 2],
        end: labels[labels.length - 1],
      });
    },
    [getLabelsForInterval]
  );

  useEffect(() => {
    console.log("Fetching dashboard data",chartData);
    fetchDashboardData();
    handleIntervalChange(activeInterval);
  }, [handleIntervalChange]);

  return (
    <>
      <CssBaseline />
      <Header />

      <Container maxWidth={isXl ? "xl" : "md"} sx={{ my: 2 }}>
        <Grid
          container
          spacing={2}
          display="flex"
          flexDirection={isSm ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
        >
          {/* Date and Dropdown with Start and End Date  */}
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            flexDirection="row" // Maintain row layout
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap" // Allow items to wrap if necessary
          >
            {/* First Row */}
            <Box
              display="flex"
              justifyContent={isSm ? "center" : "start"}
              alignItems="center"
              gap={2}
              marginBottom={isSm ? 2 : 0}
              flexGrow={1} // Allows this to take space
            >
              <Typography variant="h6" fontWeight="550">
                {todayDate}
              </Typography>
              <Select
                value={activeInterval}
                onChange={(e) => handleIntervalChange(e.target.value)}
                className="h-8 bg-[#F8F8F8] border-hidden"
                sx={{
                  fontWeight: "bold",
                  "& .MuiSelect-select": {
                    fontWeight: "bold",
                  },
                }}
              >
                <MenuItem value="daily">Today</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
              <Typography variant="body1">
                {activeInterval === "weekly"
                  ? `${startEndDate.start} - ${startEndDate.end}`
                  : startEndDate.end}
              </Typography>
            </Box>

            {/* Add Time Button */}
            <Box
              width={{ xs: "100%", sm: "auto" }}
              display={{ xs: "none", sm: "flex", md: "flex" }} // Hide on xs and sm, show from md upwards
              justifyContent={isSm ? "center" : "flex-end"}
              flexShrink={0} // Prevent the button box from shrinking
            >
              <CustomButton
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: COLORS.primary,
                  color: "white",
                  fontWeight: 600,
                  padding: "10px 15px",
                  borderRadius: "30px",
                  width: "100%", // Full width for small screens
                  maxWidth: "400px", // Add max width to prevent overflow
                }}
                onClick={() => navigate("/resource/addtime")}
              >
                Add Time
              </CustomButton>
            </Box>
          </Grid>


          {/* Cards for Total Hours, Billable Hours, Non-Billable Hours */}
          <Grid
            item
            container
            xs={12}
            sm={12}
            spacing={isSm ? 2 : 4}
            justifyContent="center"
          >
            {[
              { title: "Total Hours", icon: <AccessTimeIcon />, valueKey: "Hours" },
              { title: "Monthly Hours", icon: <CalendarMonthIcon />, valueKey: "MonthlyHours" },
              { title: "Billable Hours", icon: <AttachMoneyIcon />, valueKey: "BillableHours" },
              { title: "Non-Billable Hours", icon: <WorkOffIcon />, valueKey: "NonBillableHours" },
            ].map((item, index) => (
              <Grid item xs={6} sm={6} md={3} lg={3} key={index}>
                <Card
                  sx={{
                    height: {
                      xs: 70,  // 70px for extra small screens
                      sm: 100,  // 70px for small screens
                      md: 100, // 100px for medium and larger screens
                      lg: 100,
                      xl: 100
                    },
                    padding: "10px",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "10px",
                    boxShadow: "none",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
                    borderRadius: "8px", // Optional: adds rounded corners with shadow
                    transition: "box-shadow 0.3s ease", // Optional: smooth shadow transition
                    '&:hover': {
                      boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)", // Optional: slight shadow increase on hover
                    }
                  }}
                  style={item.valueKey === "Hours" ? { cursor: "pointer" } : undefined}
                  onClick={() => item.valueKey === "Hours" && navigate("/resource/calendar")}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    {/* Left: Title and Value */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: { xs: "center", md: "flex-start" }, // Fix alignment
                        flex: 1,
                        textAlign: { xs: "center", md: "left" }, // Fix text alignment
                      }}
                    >
                      <Typography
                        color="#8D8D8D"
                        variant="body2"
                        sx={{ marginBottom: 0.5 }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          fontSize: { xs: "1rem", md: "1.25rem" }, // Responsive font size
                        }}
                      >
                        {item.title === "Total Hours"
                          ? activeInterval === "daily"
                            ? dashboardTotals.todayHours.toFixed(2)
                            : activeInterval === "weekly"
                              ? dashboardTotals.thisWeekHours.toFixed(2)
                              : activeInterval === "monthly"
                                ? dashboardTotals.thisMonthHours.toFixed(2)
                                : dashboardTotals.thisYearHours.toFixed(2)
                          : item.title === "Billable Hours"
                            ? activeInterval === "daily"
                              ? dashboardTotals.todayBillableHours.toFixed(2)
                              : activeInterval === "weekly"
                                ? dashboardTotals.thisWeekBillableHours.toFixed(2)
                                : activeInterval === "monthly"
                                  ? dashboardTotals.thisMonthBillableHours.toFixed(2)
                                  : dashboardTotals.thisYearTotalBillable.toFixed(2)
                            : item.title === "Non-Billable Hours"
                              ? activeInterval === "daily"
                                ? dashboardTotals.todayNonBillableHours.toFixed(2)
                                : activeInterval === "weekly"
                                  ? dashboardTotals.thisWeekNonBillableHours.toFixed(2)
                                  : activeInterval === "monthly"
                                    ? dashboardTotals.thisMonthNonBillableHours.toFixed(2)
                                    : dashboardTotals.thisYearTotalNonBillable.toFixed(2)
                              : item.title === "Monthly Hours"
                                ? dashboardTotals.thisMonthHours.toFixed(2)
                                : "N/A"}{" "}
                        h
                      </Typography>
                    </Box>
                    {
                      !isSm && (
                        <Divider
                          orientation="vertical"
                          sx={{
                            height: "100%",
                            marginLeft: 2,
                            marginRight: 2,
                            borderColor: "#000",
                          }}
                        />

                      )
                    }
                    {/* Right: Icon */}
                    {!isSm && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          marginLeft: 2,
                        }}
                      >
                        {item.icon}
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sm={12} width={"100%"} 
           sx={{
            display: { 
              xs: "block", 
              sm: "none" 
            }
          }}>

            <CustomButton
              style={{
                
                fontSize: "16px",
                borderRadius: "10px",
                minWidth: "100%",
                height: "45px",
              }}
              onClick={() => navigate("/resource/addtime")}
            >
              Add Time
            </CustomButton>

          </Grid>

          {/* Chart - right side */}
          <Grid
            item
            container
            justifyContent="center"
          >
            <Box
              width="100%"
              sx={{
                padding: "10px",
                backgroundColor: "#F8F8F8",
                borderRadius: "10px",
              }}
            >
              <LineChart data={chartData} />
            </Box>
          </Grid>
        </Grid>
      </Container>

      {window.innerWidth <= 768 ? (
        <Grid item xs={12}>
          <Box sx={{ height: "70px" }}></Box> {/* Add 50px of space */}
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default ResourceDashboard;
